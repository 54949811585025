<template>
  <div class="account-list-wrap">
    <div class="button-wrap">
      <el-button type="primary" style="width: 100%" @click="openDialog('createPermission')">新增角色</el-button>
    </div>
    <el-table
      ref="listTable"
      :data="getPermissionList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
      class="search-form"
    >
      <el-table-column prop="role_name" label="角色名稱" width="200" />

      <el-table-column prop="" label="授權功能" :show-overflow-tooltip="true">
        <template #default="scope">
          <span
            v-if="
              scope.row.headquarters_permission_array.length === headOfficeArray.length ||
              scope.row.headquarters_permission_array.length === 0
            "
          >
            總公司：{{ scope.row.headquarters_permission_array.length === headOfficeArray.length ? 'All' : '-' }}
          </span>
          <span v-else>總公司：{{ scope.row.headquarters_permission_array.map((e) => e.title).join('、') }}</span>
          <br />

          <span
            v-if="
              scope.row.permission_array.length === branchOfficeArray.length || scope.row.permission_array.length === 0
            "
          >
            分公司：{{ scope.row.permission_array.length === branchOfficeArray.length ? 'All' : '-' }}
          </span>
          <span v-else>分公司：{{ scope.row.permission_array.map((e) => e.title).join('、') }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="user_array.user_name" label="使用者" width="250" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>
            {{ scope.row.user_array.map((e) => e.user_name).join('、') }}
          </span>
        </template>
      </el-table-column>

      <el-table-column fixed="right" align="right" width="150">
        <template #default="scope">
          <el-button
            type="info"
            plain
            style="font-weight: normal; border: none; width: 110px"
            @click="handleClickDetail(scope.$index, scope.row)"
          >
            詳細
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { ref, watch, defineAsyncComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDialog } from '@/utils/dialog';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'account-permission',
  components: {
    createPermissionDialog: defineAsyncComponent(() => import('@/components/account/CreatePermission.vue')),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getPermissionList, getIsDetail, getPageSize, getPageTotal, getPermissionTree } = useGetters(
      'permissionManagement',
      ['getPermissionList', 'getPageSize', 'getPageTotal', 'getIsDetail', 'getPermissionTree']
    );
    const SET_IS_DETAIL = useMutations('permissionManagement', ['SET_IS_DETAIL']);
    const { doGetPermissionList, doGetPermissionTree, doGetPermissionUserList } = useActions('permissionManagement', [
      'doGetPermissionList',
      'doGetPermissionTree',
      'doGetPermissionUserList',
    ]);
    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: route.query.page ? route.query.page : 1 };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      if (route.path === '/account/permission' && !getIsDetail.value) {
        await doGetPermissionList({ start: (currentPage.value - 1) * getPageSize.value, type: 'init' });
      } else {
        SET_IS_DETAIL(false);
      }
    });
    const headOfficeArray = ref([]);
    const branchOfficeArray = ref([]);
    //取得權限列表
    onMounted(async () => {
      await doGetPermissionTree();
      headOfficeArray.value = handlePermissionTree(getPermissionTree.value.headquarters_permission_tree);
      branchOfficeArray.value = handlePermissionTree(getPermissionTree.value.permission_tree);
    });
    // 取得授權功能總長度
    const handlePermissionTree = (office, newArray = []) => {
      office.forEach((type) => {
        type.children.map((item) => newArray.push(item));
      });
      return newArray;
    };

    //點擊分頁
    const handleCurrentChange = async () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page) {
          currentPage.value = Number(route.query.page);
          await doGetPermissionList({ start: (currentPage.value - 1) * getPageSize.value, type: 'page' });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //點擊詳細
    const SET_PERMISSION_DETAIL = useMutations('permissionManagement', ['SET_PERMISSION_DETAIL']);
    const handleClickDetail = async (index, item) => {
      SET_PERMISSION_DETAIL(item);
      await doGetPermissionUserList({ role_id: item.role_id });
      router.push(route.path + '/detail');
    };
    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return {
      getPermissionList,
      dialogTemplate,
      openDialog,
      listTable,
      currentPage,
      getPageTotal,
      getPageSize,
      getPermissionTree,
      handleCurrentChange,
      handleClickDetail,
      headOfficeArray,
      branchOfficeArray,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.account-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 110px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
